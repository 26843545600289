<template>
  <div v-show="isShow" class="toast">
    <a-spin size="large" />
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      message: 'hhhhhhh',
      isShow: false
    }
  },
  methods: {
    // show(message, duration = 1500) {
    //   console.log(this.isShow)
    //   this.isShow = true
    //   this.message = message
    //   setTimeout(() => {
    //     this.isShow = false
    //     this.message = ''
    //   }, duration)
    // }
    show() {
      this.isShow = true
    },
    dismiss() {
      this.isShow = false
    }
  }
}
</script>

<style scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  color: white;
  text-align: center;
  word-break: break-all;
}
</style>
