<template>
	<div class="home">
		<div class="top">
			<div class="content-container">>
				<div class="t-title">
					引领电商供应链技术创新
				</div>
				<div class="t-des">
					Leading Technological Innovation in Cross Border E-Commerce Supply Chain
				</div>
			</div>
		</div>
		<div class="middle-zn">
			<div class="content-container">
				<div class="m-title m-zn-title">
					智能制造与研发
				</div>
				<div>
					<div class="m-zn-card-row" style="margin-top: -5px;">
						<div class="m-zn-card">
							<img class="m-zn-card-img" src="~@/assets/icon_lefoon.png" />
							<div class="m-zn-card-content">
								<div class="m-zn-card-title">乐蜂劳务服务平台</div>
								<div class="m-zn-card-des" style="text-align: center;">
									引领人力+科技的创新
								</div>
							</div>
						</div>
						<div class="m-zn-card">
							<img class="m-zn-card-img" src="~@/assets/icon_fj_system.png" />
							<div class="m-zn-card-content">
								<div class="m-zn-card-title">创新型分拣系统</div>
								<div class="m-zn-card-des">
									利用国际领先的自主研发智能机器人技术、图像识别和智能算法，实现降低30%
									的人工成本和 60% 的占地面积的研究目标
								</div>
							</div>
						</div>
						<div class="m-zn-card">
							<img class="m-zn-card-img" src="~@/assets/icon_kdmd.png" />
							<div class="m-zn-card-content">
								<div class="m-zn-card-title">快递面单信息识别与翻译</div>
								<div class="m-zn-card-des">
									解决海关申报和跨境邮件不同语种的实时翻译
								</div>
							</div>
						</div>
					</div>
					<div class="m-zn-card-row" style="margin-top: 20px;">
						<div class="m-zn-card">
							<img class="m-zn-card-img" src="~@/assets/icon_tms.png" />
							<div class="m-zn-card-content">
								<div class="m-zn-card-title">TMS智能配载系统</div>
								<div class="m-zn-card-des">
									以大数据的实时智能算法提升装载率，降低企业网运成本
								</div>
							</div>
						</div>
						<div class="m-zn-card">
							<img class="m-zn-card-img" src="~@/assets/icon_tjcl.png" />
							<div class="m-zn-card-content">
								<div class="m-zn-card-title">智能视觉体积测量系统</div>
								<div class="m-zn-card-des">
									利用智能视觉识别系统快速完成物品的体积测量需求，解决不规则物品的准确测量
								</div>
							</div>
						</div>
						<div class="m-zn-card">
							<img class="m-zn-card-img" src="~@/assets/icon_cy.png" />
							<div class="m-zn-card-content">
								<div class="m-zn-card-title">场院管理系统</div>
								<div class="m-zn-card-des">
									实时调度车辆的进出、排队、装卸的可视化园区管理
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="middle-operation">
			<div class="content-container">
				<div class="m-title">
					运营服务
				</div>
				<div class="m-op-card-container">
					<div class="m-op-card">
						<img class="m-op-card-img" src="~@/assets/icon_zbfw.png" />
						<div class="m-op-card-content">
							<div class="m-op-card-title">整包服务</div>
							<div class="m-op-card-des">
								派遣公司（提供人力）+友邦物联（投资硬件+软件+运营团队）
							</div>
						</div>
					</div>
					<div class="m-op-card">
						<img class="m-op-card-img" src="~@/assets/icon_fjjj.png" />
						<div class="m-op-card-content">
							<div class="m-op-card-title">分拣解决方案</div>
							<div class="m-op-card-des">
								深度优化电商快递企业操作流程，提供专业智能分拣设备解决方案
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="middle-cooperate">
			<div class="content-container">
				<div class="m-cooperate-title">
					合作伙伴
				</div>
				<div class="m-cooperate-img-container">
					<img class="m-cooperate-img" src="~@/assets/tsinghua.png" />
					<img class="m-cooperate-img" src="~@/assets/china_post.png" />
					<img class="m-cooperate-img" src="~@/assets/bjhkht.png" />
					<img class="m-cooperate-img" src="~@/assets/zykj.png" />
					<img class="m-cooperate-img" src="~@/assets/zgkxy.png" />
				</div>
				<div class="f-content">
					Copyright 深圳友邦物联科技有限公司
					<a href="http://www.beian.miit.gov.cn/">粤ICP备17066262号-1</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Home',
	components: {},
	methods: {
		clickAbout() {
			this.$router.push({ name: 'About' })
		},
	},
}
</script>
<style scoped>
html,
body {
	font-size: 14px;
	margin: 0;
	min-width: 950px;
	font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.float-r {
	float: right;
}

.content-container {
	max-width: 1200px;
	margin: auto;
}

.top {
	width: 100%;
	height: 576px;
	background: url('~@/assets/banner.png') center center no-repeat;
	background-size: cover;
}
.t-title {
	margin-top: 167px;
	text-align: center;
	font-size: 46px;
	font-weight: 800;
	line-height: 44px;
	color: rgba(255, 255, 255, 1);
	letter-spacing: calc(46px * 10 / 75);
}
.t-des {
	margin-top: 24px;
	text-align: center;
	line-height: 21px;
	font-size: 22px;
	color: rgba(255, 255, 255, 1);
}

.f-content {
	margin-top: 153px;
	text-align: center;
	padding: 0 0 46px 0;
	line-height: 15px;
	font-size: 14px;
	color: rgba(153, 153, 153, 1);
	letter-spacing: 1px;
}
.f-content a,
.f-content a:hover {
	color: rgba(153, 153, 153, 1);
	text-decoration: none;
}
.m-title {
	font-size: 32px;
	font-weight: 800;
	line-height: 31px;
	text-align: center;
	color: rgba(45, 48, 54, 1);
	letter-spacing: calc(32px * 10 / 75);
}
.middle-zn {
	padding: 64px 0;
	background: linear-gradient(0deg, rgba(245, 249, 255, 1), rgba(242, 248, 254, 1));
}
.m-zn-title {
	text-align: center;
	font-size: 32px;
	font-weight: 800;
	line-height: 31px;
	color: rgba(45, 48, 54, 1);
}
.m-zn-card-row {
	display: flex;
	justify-content: center;
}
.m-zn-card {
	width: 384px;
	text-align: center;
	padding: 0 22px;
	margin-right: 24px;
}
.m-zn-card:last-child {
	margin-right: 0;
}
.m-zn-card-img {
	position: absolute;
	transform: translate(-50%);
}
.m-zn-card-content {
	margin-top: 170px;
}
.m-zn-card-title {
	line-height: 19px;
	font-size: 20px;
	font-weight: bold;
	color: rgba(45, 48, 54, 1);
	letter-spacing: 2px;
}
.m-zn-card-des {
	margin: auto;
	width: 238px;
	line-height: 24px;
	font-size: 14px;
	color: rgba(102, 102, 102, 1);
	margin-top: 16px;
	text-align: left;
}

.middle-operation {
	background: rgba(249, 251, 252, 1);
	padding: 64px 0 78px 0;
}
.m-op-card-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 36px;
}
.m-op-card {
	width: 588px;
	height: 168px;
	background: rgba(255, 255, 255, 1);
	margin-top: 24px;
	margin-right: 24px;
	display: flex;
	flex-direction: row;
}
.m-op-card:last-child {
	margin-right: 0;
}
.m-op-card:nth-of-type(1) > :last-child {
	margin-right: 0;
}
.m-op-card-img {
	width: 150px;
}
.m-op-card-content {
	width: 438px;
}
.m-op-card-title {
	font-weight: bold;
	font-size: 20px;
	line-height: 19px;
	color: rgba(45, 48, 54, 1);
	letter-spacing: calc(20px * 10 / 75);
	margin-top: 54px;
}
.m-op-card-des {
	font-size: 14px;
	color: rgba(102, 102, 102, 1);
	margin-top: 14px;
}

.middle-cooperate {
	padding: 0;
	background-color: rgba(255, 255, 255, 1);
	background: url('~@/assets/bg_cooperate.png') center center no-repeat;
	background-size: cover;
}
.m-cooperate-bg {
	position: absolute;
	width: 95%;
	height: 357px;
	left: 50%;
	transform: translate(-50%);
}
.m-cooperate-title {
	padding: 113px 0 0 0;
	text-align: center;
	line-height: 31px;
	font-size: 32px;
	font-weight: 800;
	color: rgba(45, 48, 54, 1);
}
.m-cooperate-img-container {
	position: relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 45px;
}
.m-cooperate-img {
	margin-right: 25px;
}
.m-cooperate-img:last-child {
	margin-right: 0;
}
.f-content {
	margin-top: 153px;
	text-align: center;
	padding: 0 0 46px 0;
	line-height: 15px;
	font-size: 14px;
	color: rgba(153, 153, 153, 1);
	letter-spacing: 1px;
}
.f-content a,
.f-content a:hover {
	color: rgba(153, 153, 153, 1);
	text-decoration: none;
}
</style>
