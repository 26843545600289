import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		currentPath: '',
	},
	mutations: {
		SET_CURRENTPATH: (state, path) => {
            state.currentPath = path
        },
	},
	actions: {},
	modules: {},
})
