<template>
	<div class="navbar" v-show="isShow">
		<div class="content">
			<img src="~@/assets/logo.png" alt="" />
			<router-link to="/" class="item title">友邦物联</router-link>
			<router-link to="/" class="item">首页</router-link>
			<router-link to="/track" class="item">物流追踪</router-link>
			<router-link to="/about" class="item">关于我们</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'navbar',
	computed: {
		isShow() {
            const path = this.$store.state.currentPath
            if ('/trackResult' === path || '/track' === path) {
                return false
            }
            return true
		},
	},
}
</script>

<style lang="less" scoped>
.navbar {
	position: absolute;
	color: #333;
	width: 100%;
	z-index: 9;
	.content {
		// background: red;
		width: 1000px;
		height: 31px;
		margin: 15px auto 0;
		// height: 80px;

		.item {
			margin-left: 40px;
			color: #fff;
			text-decoration: none;
			line-height: 31px;
			font-size: 15px;
		}

		.title {
			margin-left: 8px;
			color: #fff;
			text-decoration: none;
			line-height: 31px;
			font-weight: 800;
			font-size: 19px;
		}
	}
}
</style>
