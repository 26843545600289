<template>
	<div id="app">
		<navi-bar></navi-bar>
		<router-view />
	</div>
</template>
<script>
import NaviBar from '@/components/navbar'
export default {
	name: 'App',
	components: {
		NaviBar,
	},
}
</script>
<style>
#app {
	text-align: left;
	color: #333;
	padding: 0;
	margin: 0;
}
</style>
