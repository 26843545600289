import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/global.less'
import less from 'less'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import toast from '@/components/toast'
import './permisson'

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(less)
Vue.use(toast)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
